import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { environment } from '../environments/environment';

type UrlPart = {
    microserviceKey: string;
    protocol: string;
    subDomain: string;
    domain: string;
    port: string;
};

export enum EnvDomains {
    LOCAL = 'localhost',
    DEV = 'rnd.soliduslabs.app',
    STAGING = 'staging.soliduslabs.app',
    UAT_EU = 'uat-eu-central-1.soliduslabs.app',
    PROD_US = 'prod-us-east-1.soliduslabs.app',
    PROD_EU = 'prod-eu-central-1.soliduslabs.app',
    PROD_ASIA = 'prod-ap-southeast-1.soliduslabs.app',
    FIDELITY = 'fidelity.soliduslabs.app'
}

export const EnvDomainsUAT = [EnvDomains.UAT_EU];
export const EnvDomainsPROD = [EnvDomains.PROD_US, EnvDomains.PROD_EU, EnvDomains.PROD_ASIA, EnvDomains.FIDELITY];

export enum Features {
    USER_ACTIVITY_EXPORT = 'USER_ACTIVITY_EXPORT',
    ADD_TO_IGNORE_LIST_WITH_MS_ORIGIN = 'ADD_TO_IGNORE_LIST_WITH_MS_ORIGIN',
    BLOCK_LIST = 'BLOCK_LIST',
    MODEL_TESTING_NEW = 'MODEL_TESTING_NEW',
    SYSTEM_CONFIGURATION = 'SYSTEM_CONFIGURATION',
    ADVANCED_TABLE_FILTERS = 'ADVANCED_TABLE_FILTERS',
    ADVANCED_TABLE_APPLICATION_FILTERS = 'ADVANCED_TABLE_APPLICATION_FILTERS',
    ONBOARDING_TABLE_FILTERS = 'ONBOARDING_TABLE_FILTERS',
    MARKET_MAKER_TABLE = 'MARKET_MAKER_TABLE',
    BID_ASK_SPREAD_TABLE = 'BID_ASK_SPREAD_TABLE',
    AUTH_WITH_SSO = 'AUTH_WITH_SSO',
    ADMIN_USERS_TABLE_FILTERS = 'ADMIN_USERS_TABLE_FILTERS',
    CASE_DETAILS_ALERT_TABLE_WITH_FILTERS = 'CASE_DETAILS_ALERT_TABLE_WITH_FILTERS',
    ACTIVITIES_TABLE_WITH_FILTERS = 'ACTIVITIES_TABLE_WITH_FILTERS',
    FILE_UPLOAD_TABLE_WITH_FILTERS = 'FILE_UPLOAD_TABLE_WITH_FILTERS',
    REST_API_TABLE_WITH_FILTERS = 'REST_API_TABLE_WITH_FILTERS',
    API_KEY = 'API_KEY',
    BULK_CASE_DETAILS_EXPORT = 'BULK_CASE_DETAILS_EXPORT',
    KYC_POLICY = 'KYC_POLICY',
    ZENDESK = 'ZENDESK',
    MS_ORIGIN_LIST_SEPARATION = 'MS_ORIGIN_LIST_SEPARATION',
    MI_COINBASE_PRIVATE_EXCHANGES = 'MI_COINBASE_PRIVATE_EXCHAGNES',
    MI_DONT_OPEN_ORDERBOOK_FROM_CHART = 'MI_DONT_OPEN_ORDERBOOK_FROM_CHART',
    AG_GRID_TABLE_ADVANCED_SEARCH_CASES = 'AG_GRID_TABLE_ADVANCED_SEARCH_CASES',
    PERSONAL_SETTING_EMAIL_NOTIFICATIONS = 'PERSONAL_SETTING_EMAIL_NOTIFICATIONS',
    WEEKLY_REPORT_V2 = 'WEEKLY_REPORT_V2',
    MARKET_INSPECTION_DERIVATIVES = 'MARKET_INSPECTION_DERIVATIVES',
    DATA_AUDIT_DISPLAY_RT_STATS_TAB = 'DATA_AUDIT_DISPLAY_RT_STATS_TAB',
    DISABLE_ADMIN_PAGE_HMAC = 'DISABLE_ADMIN_PAGE_HMAC',
    DISABLE_ADMIN_PAGE_WEBHOOKS = 'DISABLE_ADMIN_PAGE_WEBHOOKS',
    ONB_EDIT_SIGN_UP_EMAIL = 'ONB_EDIT_SIGN_UP_EMAIL',
    STAKING_GUARD_REPORT = 'STAKING_GUARD_REPORT',
    MI_ALERT_VIEW_USE_COUNTERPARTY_CLIENTS = 'MI_ALERT_VIEW_USE_COUNTERPARTY_CLIENTS',
    MARKET_INSPECTION_HIDE_ORDERBOOK_PRIVATE_BBO = 'MARKET_INSPECTION_HIDE_ORDERBOOK_PRIVATE_BBO',
    MS_MS_SEGMENTATION = 'MS_MS_SEGMENTATION',
    MI_DISABLE_SMART_ZOOM = 'MI_DISABLE_SMART_ZOOM',
    MARKET_INSPECTION_METRICS_API_CALCULATION = 'MARKET_INSPECTION_METRICS_API_CALCULATION',
    MI_USE_MARKET_DATA_API_CHART = 'MI_USE_MARKET_DATA_API_CHART',
    MI_CROSS_PRODUCT_MULTIPLE_SYMBOLS_SUPPORT = 'MI_CROSS_PRODUCT_MULTIPLE_SYMBOLS_SUPPORT',
    MI_API_V5_ENABLE = 'MI_API_V5_ENABLE',
    ELLIPTIC_MANUAL_ALERT_EVENT_RETRY_FETCHING = 'ELLIPTIC_MANUAL_ALERT_EVENT_RETRY_FETCHING',
    UCV_RECORDS_UPLOAD = 'UCV_RECORDS_UPLOAD',
    ALERT_REPORT_PDF_EXPORT = 'ALERT_REPORT_PDF_EXPORT',
    AG_GRID_SYSTEM_FILE_UPLOAD = 'AG_GRID_SYSTEM_FILE_UPLOAD',
    TM_IGNORE_LIST_V2 = 'TM_IGNORE_LIST_V2',
    CLIENT_DATA_VIEW = 'CLIENT_DATA_VIEW',
    MI_SUB_AGGREGATION = 'MI_SUB_AGGREGATION',
    MI_TRADE_HISTORY = 'MI_TRADE_HISTORY',
    CLIENT_ACTIVITY_ANALYTICS_DYNAMIC_DESCRIPTION = 'CLIENT_ACTIVITY_ANALYTICS_DYNAMIC_DESCRIPTION',
    ADMIN_SYSTEM_SESSION_TTL_12_HRS = 'ADMIN_SYSTEM_SESSION_TTL_12_HRS'
}

const DisabledFeatures: { [key in Features]?: boolean } = {
    // FEATURE FLAGS ALWAYS DISABLED (14)
    [Features.ADD_TO_IGNORE_LIST_WITH_MS_ORIGIN]: true,
    [Features.ACTIVITIES_TABLE_WITH_FILTERS]: true,
    [Features.FILE_UPLOAD_TABLE_WITH_FILTERS]: true,
    [Features.REST_API_TABLE_WITH_FILTERS]: true,
    [Features.ADMIN_USERS_TABLE_FILTERS]: true,
    [Features.ADVANCED_TABLE_FILTERS]: true,
    [Features.BLOCK_LIST]: true,
    [Features.USER_ACTIVITY_EXPORT]: true,
    // FEATURE FLAGS ALLOWED ONLY FOR SOME ENVS (12)
    [Features.ADVANCED_TABLE_APPLICATION_FILTERS]: false,
    [Features.AUTH_WITH_SSO]: false,
    [Features.CASE_DETAILS_ALERT_TABLE_WITH_FILTERS]: false,
    [Features.ONBOARDING_TABLE_FILTERS]: false,
    [Features.SYSTEM_CONFIGURATION]: false,
    [Features.API_KEY]: false,
    [Features.ZENDESK]: false
};

const DisabledFeaturesOnEnvs: { [key in Features]?: EnvDomains[] } = {
    [Features.AUTH_WITH_SSO]: [
        EnvDomains.LOCAL,
        EnvDomains.DEV,
        EnvDomains.UAT_EU,
        EnvDomains.STAGING,
        EnvDomains.PROD_US,
        EnvDomains.PROD_EU,
        EnvDomains.PROD_ASIA
    ],
    [Features.API_KEY]: [...EnvDomainsUAT, ...EnvDomainsPROD],
    [Features.ZENDESK]: [...EnvDomainsUAT, ...EnvDomainsPROD]
};

@Injectable({
    providedIn: 'root'
})
export class AppConfigService {
    public isLogoAnimatedSubject = new BehaviorSubject<boolean>(false);
    public isLogoAnimated$ = this.isLogoAnimatedSubject.asObservable().pipe(distinctUntilChanged());

    get(microserviceKey: string): UrlPart {
        if (microserviceKey && environment?.microServices?.[microserviceKey]) {
            return {
                microserviceKey: microserviceKey,
                protocol: environment.protocol,
                domain: environment.domain,
                subDomain: environment.microServices[microserviceKey].subDomain,
                port: environment.microServices[microserviceKey].port
            };
        }
        return null;
    }

    isFeatureDisabled(featureFlag: Features): boolean {
        return (
            DisabledFeatures[featureFlag] ||
            DisabledFeaturesOnEnvs[featureFlag]?.includes(environment.domain as EnvDomains) ||
            false
        );
    }
}
